import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

const Success = () => {
  return (
    <Container>
      <div className="card">
        <p>Your message has been received and I will respond as soon as possible.</p>
        <Link to="/" >Back</Link>
      </div>
    </Container>
  )
}

export default Success


const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  place-items: center;

  .card {
    padding: 40px 20px;
    border-radius: 3px;
    box-shadow: 0 2px 8px 0 rgba(0,0,0,.36);
    text-align: center;
  }

  a {
    text-decoration: none;
    font-size: 1.2rem;
    color: #777;

    &:hover {
      color: #333;
    }
  }
`